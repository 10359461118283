.dev-multiview {

  height: 100%;
  overflow: auto;

  .dev-container {
    display: flex;
    flex-flow: row;
    height: 100%;
  }

  .dev-content {
    height: 100%;
    outline: #BDBDBD solid 1px;
  }
}
