.primary-btn {
  background-color: $primary-color;
  color: white;
  border-radius: 0px;
}


.accent-btn {
  background-color: #F5F5F5;
  color: #BDBDBD;

  &-dropdown {
    background-color: #F5F5F5;
    color: #646464;
    border-radius: 0px;
  }

  &--active {
    border-radius: 0px;
    background-color: $accent;
    color: white;
    border-radius: 0px;
  }
}

.secondary-btn {
  background-color: $background-light;
  color: $accent;
  border-radius: 0px;
  font-weight: 500;
  border: 1px solid $accent;
}

.cart-btn {
  background-color: #F5F5F5;
  border-radius: 0px;
  width: 30px;
  height: 30px;
  font-size: 20px;
  font-weight: 800;
  color: $gray;
  padding: 0px;
}

.secondary-cover-btn {
  border-radius: 0px;
  background-color: $accent;
  color: white;
}

.back-btn {
  width: 20px !important;
  height: 20px;
  display: block;
  margin: 0;
}
