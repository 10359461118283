.body-modal {
  img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;;
  }

  .add-to-cart {
    padding: 20px;

    &__footer {
      .add-item {
        width: 100%;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .primary-bg {
    background: #FFFBEB;
    padding: 10px 20px;
    margin-bottom: 10px;

    p {
      margin-bottom: 0;
    }
  }
}

.red {
  color: red;
}

.cart-item-add {
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;

  .item-details {
    display: flex;
    width: 75%;
  }

  .checkbox {
    width: 90%;
  }
}

.cart-item-modifier {
  .primary-check {
    margin-left: 0;
  }
}
