.add-item-group {
  span {
    margin: 0 10px;
  }
}

.cart-item-group {
  width: 90%;
  margin-bottom: 10px;

  .cart-item {
    width: 100%;

    &-name {
      font-size: 20px;
      color: $dark;
      margin-bottom: 15px;
    }

    &-price {
      font-size: 20px;
    }

    &-modificator {
      h5 {
        color: gray;
        font-weight: bold;
        font-size: 16px;
      }

      p {
        margin-bottom: 5px;
      }
    }
  }
}
