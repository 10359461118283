.navbar {
  &__theme-light {
    background-color: white;
  }

  &__theme-dark {
    background-color: $dark;
  }

  .container {
    justify-content: space-between;
  }

  ul {
    color: white;
  }

  .navbar-brand {
    .toggler {
      margin: 0px 15px;
    }

    img {
      max-width: 9.5rem;
      margin: 0px 15px;
    }
  }

  .searcher {
    display: flex;

    .ico {
      z-index: 2;
      margin-right: -30px;
    }

    input {
      min-width: 450px;
      padding-left: 38px;
    }
  }

  .user-ico {
    margin: 0px 10px;
    max-height: 15px;
    max-width: 15px;
  }
}
