.checkbox-group {
  .checkbox__header {
    display: flex;
    justify-content: space-between;

    h2 {
      margin: 20px 0px;
    }
  }

  .form-check {
    margin: 5px 0;

    label {
      font-weight: 600;
    }

    p {
      margin: 10px 0px;
    }
  }
}

.note {
  width: 50%;
  margin-top: -45px;
}

.mapIframe {
  width: 100%;

  iframe {
    width: 100%;
    height: 300px;
    frameBorder: 0;
    scrolling: no;
    marginHeight: 0;
    marginWidth: 0;
  }
}

.primary-check {
  border: 1px solid $primary-color;
  float: left;
  margin-left: -1.5em;

  &:focus {
    border-color: $primary-color;
  }

  &:checked {
    background-color: $primary-color;
    border-color: $primary-color;
  }

}

.secondary-text {
  margin: 0 10px;
  font-size: 13px !important;
  color: $gray;
}
