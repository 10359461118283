.auth {
  margin: auto;
  max-width: 750px;
  text-align: center;

  img {
    margin: 25px auto;
    width: 50%;
  }

  h1 {
    margin: 30px auto;
  }

  .form-group {
    margin: 50px 0px 20px 0px;
    text-align: left;

    label {
      margin: 10px 0;
      margin: 10px 0;
      color: $gray;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
    }

    input {
      margin: 10px 0px;
    }
  }

  .btn {
    width: 100%;
    margin-bottom: 15px;
  }

  .text-muted {
    margin: 10px 0;
  }

}
