.restaurant-information {
  padding-bottom: 2rem !important;
  padding-top: 2rem !important;

  &__list {
    margin: 30px 0;
  }

  .information__item {
    display: flex;
    align-items: center;
    margin: 5px 0;

    .ico {
      height: 18px;
      width: 18px;
    }

    p {
      color: $dark;
      margin: 0 10px;
    }

    span: 0px 10px;
  }

  a {
    color: $dark;
    text-decoration: auto;
  }

  .clock-toggle {
    text-decoration: none;
    color: $gray;
    margin: 0 10px;
  }
}
