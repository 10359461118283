.primary-input {
  background: #FAF9FF;
  border: 2px solid #F5F5F5;
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 30px;
  min-height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  -moz-appearance: none;
  appearance: none;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


.wrapper {
  margin: auto auto 2rem;
  column-gap: 60px;
  row-gap: 0.5rem;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(1, 1fr);
  @include media('>tablet') {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
  }
  @include media('>desktop') {
    grid-template-columns: repeat(3, 1fr);
  }
}
