// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.subHeader {
  margin-bottom: 1rem;
  background-color: $dark;
  text-align: center;
  max-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;


  @include media('>tablet') {
    margin-bottom: 2.5rem;
  }
  @include media('>desktop') {
    margin-bottom: 5rem;
  }

  .restaurant__banner {
    width: 100%;
    object-fit: cover;
    opacity: 1;
    max-height: 15rem;

    @include media('>tablet') {
      max-height: 18rem;
    }
    @include media('>desktop') {
      max-height: 22rem;
    }
  }

  .restaurant__payments {
    z-index: 2;
    position: absolute;
    background-color: rgba(59, 58, 60, 0.69);
    padding: 5px 15px;
    top: 385px;
    right: 100px;

    img {
      margin: 0 10px;
    }

  }

  span {
    color: $primary-color;
  }

  h1, p {
    margin: 15px 0px;
    color: white;

  }
}
