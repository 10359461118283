
h1 {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;

  span {
    color: $primary-color;
  }
}

h2 {
  font-size: 28px;
  color: $gray;
}

.accent {
  color: $accent;
}
