@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');

#root {
  font-family: 'Raleway';

  p {
    font-family: 'Open Sans';
  }
}

p {
  color: $gray;
}
