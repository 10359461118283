.card {
  border-radius: 0px;
  background: #FAF9FF;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
  0px 8px 8px -4px rgba(24, 39, 75, 0.08);

  &--right {
    flex-direction: row-reverse;
    display: flex;
  }

  &--bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba($dark, 0.4);
  }

  &__content {
    width: 100%;
  }
}

.restaurant {
  max-height: 15rem;
  cursor: pointer;

  &__img {
    max-height: 7rem;
    height: 50%;
    object-fit: cover;
    width: 100%;
  }

  &__information {
    height: 50%;
    margin: 10px;

    .direction {
      display: flex;
    }

    h4 {
      font-size: 16px;
    }

    p {
      margin: 0px 10px;
    }
  }
}

.food {

  max-height: 12rem;
  cursor: default;

  &__img {
    width: 100%;
    height: 100%;

    img {
      height: 99%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__information {
    padding: 15px 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  // TODO Fix esto que no se como hacerlo correctamente
  food__img + food__information {
    width: 50%;
  }

  food__img + food__information {
    width: 50%;
  }

  .description {
    flex-grow: 1;

    p {
      font-size: 14px;
    }
  }

  .price {
    width: 100%;
    align-self: flex-end;

    p {
      color: $dark;
      margin: 0;
    }
  }

  h4 {
    font-size: 20px;
  }

  &--active {
    border: 1px solid $accent;
  }
}

.cards-group {
  h2 {
    margin: 25px 0;
  }
}
